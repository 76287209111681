import "./css/tailwind.css";

let $headerBG;
let $loveText;
let $loveVideo;
let $digitalText;
let $digitalVideo;

const cacheDOM = () =>{
  $headerBG = document.getElementById("header");
  $loveVideo = document.querySelector(".love-bg");
  $digitalVideo = document.querySelector(".digital-bg");
  $loveText = document.getElementById("love");
  $digitalText = document.getElementById("digital");
}

const initLove = () =>{
  $loveText.addEventListener('mouseenter',()=>{
    console.log("enter")
    $loveVideo.classList.remove("hidden")
  })
  $loveText.addEventListener('mouseleave',()=>{
    console.log("exit")
    $loveVideo.classList.add("hidden")
  })
}
const initDigital = () =>{
  $digitalText.addEventListener('mouseenter',()=>{
    $digitalVideo.classList.remove("hidden")
  })
  $digitalText.addEventListener('mouseleave',()=>{
    $digitalVideo.classList.add("hidden")
  })
}
document.addEventListener("DOMContentLoaded", function execWhenDomContentLoaded(event) {
  cacheDOM();
  initLove();
  initDigital();
});


// Animation Code
// gsap.timeline()
//     .delay(2)
//     .set('body img', {scale: 1.75})
//     .fromTo('#monax-screen', {y: '1000px',}, {opacity:1, duration: 7, y: '-800px', ease:"power1"})
//     .fromTo('#trivianow-screen', {y: '1000px',}, {opacity:1, duration: 7, y: '-800px', ease:"power1"}, "<7.5%")
//     .fromTo('#ewc-screen', {y: '1000px',}, {opacity:1, duration: 7, y: '-800px', ease:"power1"}, "<15%")
//     .fromTo('#aot-screen', {y: '1000px',}, {opacity:1, duration: 7, y: '-800px', ease:"power1"}, "<5%")
//     .fromTo('#soundlog-screen', {y: '1000px',}, {opacity:1, duration: 7, y: '-800px', ease:"power1"}, "<15%")
//     .fromTo('#agame-screen', {y: '1000px',}, {opacity:1, duration: 7, y: '-800px', ease:"power1"}, "<10%")
//     .repeat(5)
//     // .to('#trivianow-screen', {y:"-200", scale: 1, duration: 1.5, ease:"power2"})
//     // .from('#monax-screen', {y:"1000px", duration:2, ease:"power2"})
//     // .from('#ewc-screen', {y:"1000px", duration:2, ease:"power2"})
//     // .from('#soundlog-screen', {y:"1000px", duration:2, ease:"power2"})
//     // .from('#trivianow-screen', {y:"1000px", duration:2, ease:"power2"})
    
